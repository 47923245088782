.defButton {
  position: relative;
  top: -10px;
  margin-right: 63px;
  border-radius: 3px;
  padding: 0.5rem 0;
  background: #4caf30;
  color: white;
  cursor: pointer;
  border: 2px solid #4caf30;
  width: 156px;
  height: 46px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}
.defButton:hover {
  transform: scale(1.02);
}
.loginButton {
  margin-right: 38px;
  background: transparent;
  color: #4caf50;
  width: 107px;
}
.startButton {
  width: 402px;
  height: 60px;
}
.learnButton {
  margin-left: 52px;
}
