@import "../../assets/css/fonts.css";
@import "../../App.css";

input::placeholder {
  color: #cccccc;
}

.startedText {
  margin-top: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.form-container {
  width: 529px;
  height: 580px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.labels {
  margin: 30px 0;
  padding-left: 0;
  margin-left: 0;
  text-align: left;
  height: 18px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-family: "AvenirNextCyr-Medium";
}

input:focus::placeholder {
  color: transparent;
}
.outlineButton {
  border: 1px solid #4caf50 !important;
  color: #4caf50 !important;
  background: transparent !important;
}
.input-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  /* width: 100%; */
  align-items: left;
  grid-template-columns: 0fr !important;
}
.defButton {
  align-items: center;
}
.input-empty {
  color: #707070;
  background: #f5fbf6;
  height: 60px;
  border: 0;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid var(--unnamed-color-b3b3b347);
  /* padding: 0.9rem 1.25rem; */
  position: relative;
}
.placeholder {
  padding-left: 20px;
  font-family: "AvenirNextCyr-Bold";
  font-size: 18px;
  text-align: center;
  letter-spacing: 0px;
  color: #cccccc;
}
form div label {
  color: #0e1111;
  font-size: 1rem;
}
form div {
  position: relative;
}
input:focus ~ label {
  font-size: 0.7rem;
}
input:focus {
  outline: none;
}
.input-error {
  border-bottom: 1px solid #db7302;
}

.checkbox-container input {
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  width: 20px;
  height: 20px;
}

.checkbox-container {
  color: #707070;
  font-size: 18px;
  cursor: pointer;
  font-family: "AvenirNextCyr-Medium";
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
}
.bottomForm {
  width: 424px;
  height: 52px;
  font-family: "AvenirNextCyr-Regular";
  font-size: 18px;
  text-align: center;
  letter-spacing: 0px;
  color: #cccccc;
}
.under {
  text-decoration: underline;
}

.button {
  width: 402px;
  height: 60px;
  margin-top: 10px;
  margin-right: 0;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  background: #4caf50;
  margin-bottom: 25px;
  padding: 0.8rem 1.3rem;
  cursor: pointer;
}

@import "../../assets/css/fonts.css";
@import "../../App.css";

.started {
  display: flex;
  text-align: center;
  flex-direction: column;
}
.formHeader {
  font-family: "AvenirNextCyr-Medium";
  font-size: 32px;
  color: #707070;
}
.pageText {
  font-family: "AvenirNextCyr-Bold";
  font-size: 42px;
  text-align: center;
  letter-spacing: 0px;
  color: #4caf30;
  opacity: 1;
}
.startedContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.clock {
  /* width: 490px;
  height: 253px; */
  opacity: 1;
  background-color: transparent;
}
@media only screen and (max-width: 1080px) {
  .startedContent {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 414px) {
  .checkbox-container input {
    width: 100px;
  }
  .checkbox-container {
    margin-left: 30px;
    font-size: 14px;
    display: flex;
  }
  .buttomForm {
    width: 330px;
    height: 48px;
    font-size: 14px;
    margin-left: 10px;
  }
  .pageText {
    margin-left: 5em;
    width: 164px;
    height: 58px;
    font-size: 24px;
  }
  .button {
    width: 327px;
    height: 46px;
  }
  .input-empty {
    margin: 0;
    width: 100%;
    height: 40px;
  }
  .startedText {
    font-size: 10px;
    width: 102px;
    height: 13px;
    margin-left: 120px;
  }
  .input-container {
    margin-right: 0;
    position: relative;
    top: 70px;
  }
  .form-container {
    margin-right: 0;
    width: 320px;
  }
  .clock {
    margin-left: 85px;
    /* width: 254px;
    height: 131px; */
  }
}
