@import "../../assets/css/fonts.css";
@import "../../../src/App.css";

.rec {
  margin-top: 23.01px;
  padding: 15px;
  height: 225px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0px 8px 21px #0000000d;
  opacity: 1;
}
.testimonialText {
  width: 70%;
  height: 70px;
  font: AvenirNextCyr-Medium;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.small-rec {
  margin-left: 37px;
  margin-top: 40px;
}
.name {
  top: 6711px;
  left: 1061px;
  width: 43px;
  height: 19px;
  font-weight: bold;
  font: AvenirNextCyr-Bold;
  color: #999999;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
}
.testImage {
  /* margin-bottom: 61px; */
  margin-top: -160px;
  margin-right: 30px;
  float: right;
  display: inline-block;
}
.test {
  /* position: absolute; */
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}
.testContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 3em;
}
.Successtext1 {
  left: 141px;
  width: 490px;
  height: 119px;
  font-family: AvenirNextCyr-Bold;
  font-size: 50px;
  text-align: left;
  letter-spacing: 0px;
  color: #4caf50;
  opacity: 1;
}
.Successtext2 {
  margin-top: 56px;
  left: 141px;
  width: 600px;
  height: 48px;
  font-family: "AvenirNextCyr-Medium";
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #999999;
}
.successbuttontext {
  /* left: 255px; */
  width: 255px;
  height: 24px;
  font: "AvenirNextCyr-Medium";
  text-align: center;
  text-decoration: underline;
  font-size: 20px;
  letter-spacing: 0px;
  color: #4caf50;
  opacity: 1;
}
.pattern {
  position: absolute;
  margin-top: 300px;
  left: 0px;
  width: 100%;
  height: 757px;
  opacity: 1;
  z-index: -1;
}
@media only screen and (max-width: 1080px) {
  .testContainer,
  .test {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .recs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Successtext1 {
    width: 60%;
    height: 58px;
    font-family: AvenirNextCyr-Bold;
    font-size: 30px;
    text-align: center;
  }
  .Successtext2 {
    text-align: center;
    width: 80%;
    height: 42px;
    font-size: 20px;
  }
  .successbuttontext {
    margin-top: 12px;
    /* width: 100%; */
    height: 13px;
    font: AvenirNextCyr-Medium;
    text-align: center;
    text-decoration: underline;
    font-size: 15px;
    letter-spacing: 0px;
    color: #4caf50;
    opacity: 1;
  }
}
@media only screen and (max-width: 414px) {
  .rec {
    width: 328px;
    height: 105px;
    font-size: 10px;
    top: 5940px;
    left: 45px;
  }
  .icon {
    width: 15px;
    margin-top: -180px;
  }
  .testimonialText {
    margin-top: 2px;
    font-size: 0.6rem;
    width: 190px;
  }
  .small-rec {
    margin-top: 15px;
    margin-left: 17px;
  }
  .name {
    margin-top: -25px;
    font-size: 8px;
  }
  .testImage {
    margin-top: -90px;
    margin-right: 20px;
    width: 60px;
  }
}
